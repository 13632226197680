@import "../../Styles/master";

.map-map {
  border-top: 2px solid $accent-dark;
  border-bottom: 2px solid $accent-dark;
  border-right-width: 0 !important;
  border-left-width: 0 !important;
}

.map,
.map-map {
  box-sizing: border-box;
  width: 100%;
  height: 40vh;
  margin: 0;
  padding: 0;
  //border: none !important;

  @include responsive(tablet) {
    height: 80vw;
  }

  @include responsive(mobile) {
    height: 100vw;
  }
}
