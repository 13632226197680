@import "../../Styles/master";

$arrowSize: 1.5rem;

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    display: none;
  }
}

#carousel-container,
#carousel-image {
  background-size: "cover";
  background-position: "center";
}

#carousel-container {
  height: 80vh;

  @include responsive(tablet) {
    height: 70vw;
  }
}

#carousel-container {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  #carousel-textContainer {
    z-index: 1;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    #carousel-text {
      @include headerText;
    }
  }

  #carousel-image {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &.carousel-imageFade {
      animation-name: fade-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    .carousel-arrow {
      animation: fade-in;
      animation-duration: 1s;
    }
  }

  &:not(:hover) {
    .carousel-arrow {
      animation: fade-out;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }

  .carousel-arrow {
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    width: $arrowSize;
    height: $arrowSize;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-$arrowSize / 2);
    cursor: pointer;
    transition: color 0.3s linear;

    &:hover {
      color: $accent-bright;
    }

    @include responsive(tabletSmall) {
      display: none;
    }
  }

  #carousel-arrowRight {
    right: 1rem;
  }

  #carousel-arrowLeft {
    left: 1rem;
  }
}
