// imported fonts
@font-face {
  font-family: "Bebas";
  src: url("../Fonts/Bebas/69efc83f-ff1e-4b84-aaac-c35e238e33ca.eot?#iefix");
  src: url("../Fonts/Bebas/69efc83f-ff1e-4b84-aaac-c35e238e33ca.eot?#iefix")
      format("eot"),
    url("../Fonts/Bebas/11e21f91-5c9d-4170-908d-d6afa916d2fc.woff2")
      format("woff2"),
    url("../Fonts/Bebas/b336f9f4-ee3c-4257-a4af-d5d2e3671b86.woff")
      format("woff"),
    url("../Fonts/Bebas/213f5fa9-0ccb-4f43-baf8-99d81c93b2ae.ttf")
      format("truetype");
}

$media: screen;

$mobileSmall: 375px;
$mobile: 450px;
$tabletSmall: 600px;
$tablet: 700px;
$desktop: 800px;
$widescreen: 1100px;
$thiccc: 1400px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobileSmall {
    @media #{$media} and (max-width: $mobileSmall) {
      @content;
    }
  } @else if $breakpoint == mobile {
    @media #{$media} and (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == tabletSmall {
    @media #{$media} and (max-width: $tabletSmall) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and (max-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktopSmallerThan {
    @media #{$media} and (max-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == desktopSmall {
    @media #{$media} and (min-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and (min-width: $desktop) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width: $widescreen) {
      @content;
    }
  } @else if $breakpoint == thiccc {
    @media #{$media} and (min-width: $thiccc) {
      @content;
    }
  }
}

// Colors ////////////////////////////////////////////
$background: black;
$background-text: white;
$background-accent: #e6e3e3;
$background-accent-text: black;
$background-transparent: #ffffffc9;
$noticeBar: #bdf91d;
$noticeBar-text: #36454f;
$primary: #535663;
$primary-text: #d8d8d8;
$secondary: #ff7f00;
$secondary-text: black;
$accent-dark: #bdf91d;
$accent-dark-text: white;
$accent-bright: #bdf91d;
$accent-bright-text: #36454f;
$accent-bright-transparent-background: #36454f99;
$servicePanel-text: #cbf2f1;
$infoBar: #606a90;
$infoBar-text: $accent-bright;
$ab-theme-medium: #6dd8ff;
$form-background-color: #cecedd;
$form-color: $primary;

// Fonts /////////////////////////////////////////////
$standard-font: "Roboto";
$heading-font: "Bebas";
$subheading-font: "Bebas";
$link-font: "Bebas";
$name-font: "Arial";
$builtByLink-font: "Orbitron";

// Sizes /////////////////////////////////////////////
$size-tiny: 0.5rem;
$size-small: 0.65rem;
$size-medium: 0.75rem;
$size-large: 1rem;
$size-subheading: 1.25rem;
$size-heading: 1.5rem;
$size-pageHeading: 2rem;
$size-extraThiccc: 2.5rem;

$header-image-height-desktop: 20rem;
$header-image-height-mobile: 80vw;

$link-height: 1rem;
$service-link-height-desktop: 1.5rem;
$mobileLink-height: 1.25rem;
$serviceLinks-width: 10rem;
$topNav-height: 3rem;
$topNav-heightMobile: 2rem;
$stickyNav-height: 1.75rem;

$form-height: 16rem;
$form-input-width: 20rem;
$form-input-spacing: 0.25rem;
$form-header-font-size: 2rem;
$form-message-input-min-height: 6rem;

// Text Outline //////////////////////////////////////
$header-outline: -1px -1px 0 $accent-bright-text, 1px -1px 0 $accent-bright-text,
  -1px 1px 0 $accent-bright-text, 1px 1px 0 $accent-bright-text;

$header-outline-bright: -1px -1px 0 $accent-bright, 1px -1px 0 $accent-bright,
  -1px 1px 0 $accent-bright, 1px 1px 0 $accent-bright;

$header-outline-thiccc: -2px -2px 0 $accent-bright-text,
  2px -2px 0 $accent-bright-text, -2px 2px 0 $accent-bright-text,
  2px 2px 0 $accent-bright-text;

// MixIns //////////////////////////////////////////
@mixin title {
  box-sizing: border-box;
  font-family: $heading-font;
  font-size: $size-pageHeading;
  color: $secondary;
  padding: 0.1rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  letter-spacing: 2px;

  @include responsive(tabletSmall) {
    font-size: $size-heading;
  }
}
@mixin subtitle {
  box-sizing: border-box;
  font-family: $subheading-font;
  font-size: $size-subheading;
  color: $secondary;
  padding: 0.1rem;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  width: 100%;
  text-align: center;
  word-wrap: break-word;
  letter-spacing: 1px;
}

@mixin paragraphText {
  box-sizing: border-box;
  font-family: $standard-font;
  font-size: $size-medium;
  color: black;
  padding: 0.1rem;
  padding-right: 0.2rem;
  padding-left: 0.2rem;
  margin: auto;
  text-align: center;
  word-wrap: break-word;

  &:not(last-child) {
    padding-bottom: 0.35rem;
  }
  @include responsive(desktop) {
    width: 90%;
  }

  @include responsive(tabletSmall) {
    font-size: $size-small;
  }
}

@mixin parallax {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $header-image-height-mobile;
  max-height: 70vh;

  @include responsive(widescreen) {
    height: $header-image-height-desktop;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    background-attachment: scroll;
  }
}

@mixin headerText {
  font-family: $size-heading;
  font-size: $heading-font;
  color: $primary;
  text-shadow: $header-outline;
  text-align: center;

  // @include responsive(mobile) {
  //   font-size: $size-subheading;
  // }
}

@mixin shadow($color, $thickness) {
  text-shadow: (-$thickness) (-$thickness) 0 $color,
    $thickness (-$thickness) 0 $color, -$thickness $thickness 0 $color,
    $thickness $thickness 0 $color !important;
}
