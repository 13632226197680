@import "../../Styles/master";

#info {
  background-color: $infoBar;
  color: $infoBar-text;
  font-family: $standard-font;
  font-size: $size-small;
  display: flex;
  align-items: center;
  min-height: 1.25rem;

  @include responsive(tablet) {
    font-size: $size-tiny;
  }

  @include responsive(tabletSmall) {
    flex-wrap: wrap;
    justify-content: center;

    #info-email {
      margin-left: 0 !important;
      word-break: break-all;
    }

    #info-linkedInLink {
      margin-right: 1rem;
    }
  }

  .info-link {
    display: flex;
    align-items: center;
    padding-left: 0.25rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;

    .info-socialMediaIcon {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;

      &:hover {
        box-shadow: 0 0 3px 2px $secondary;
      }

      @include responsive(mobile) {
        width: 0.75rem;
        height: 0.75rem;
      }
    }
  }

  #info-phone,
  #info-email {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.25rem;

    #info-mailLink,
    #info-phoneLink {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: $infoBar-text;
    }

    .info-infoIcon {
      font-size: $size-large;
      color: $infoBar-text;
      padding-right: 0.1rem;

      @include responsive(mobile) {
        font-size: $size-medium;
      }
    }
  }

  #info-email {
    margin-left: auto;
    padding-right: 0.25rem;
  }
}
