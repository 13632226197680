@import "../../Styles/master";

.serviceLinks {
  background-image: url(../../Media/Images/backgrounds/background.jpg);
  background-size: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .serviceLinks-title {
    @include title;
    color: $servicePanel-text !important;
    padding-bottom: 2rem;
    flex-basis: 100%;
    text-shadow: $header-outline-thiccc;
    letter-spacing: 2.5px !important;
    @include responsive(mobile) {
      padding-bottom: 1rem;
    }
  }

  .serviceLinks-container {
    flex-basis: 33%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive(thiccc) {
      flex-basis: 20%;
    }

    @include responsive(desktopSmallerThan) {
      flex-basis: 50%;
    }

    @include responsive(mobile) {
      flex-basis: 100%;
    }

    .serviceLinks-link {
      text-decoration: none !important;
      width: fit-content;

      .serviceLinks-icon {
        width: 6rem;
        display: block;
        margin: auto;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;

        @include responsive(mobile) {
          width: 75%;
        }

        @include responsive(thiccc) {
          width: 8rem;
        }
      }

      &:hover {
        .serviceLinks-text {
          color: $accent-bright !important;
        }
      }

      .serviceLinks-text {
        transition: color 0.3s linear;
        clear: both;
        white-space: pre;
        @include subtitle;
        color: $servicePanel-text !important;
        text-shadow: $header-outline-thiccc;
        letter-spacing: 2px !important;
        margin-bottom: 1rem;

        @include responsive(mobile) {
          font-size: $size-subheading !important;
        }
      }
    }
  }
}
