@import "../../Styles/master";

#separatorContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: auto;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .separatorLine {
    margin: auto;
    transition: width 0.5s ease-out;
    width: 0%;
  }

  .separatorLineDark {
    border-bottom: solid 2px $accent-dark;
  }

  .separatorLineLight {
    border-bottom: solid 2px $primary;
  }

  .separatorLineGrown {
    width: 80% !important;
  }
}
