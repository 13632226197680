@import "../../../Styles/master";

#home-pool {
  background-image: url(../../../Media/Images/pool.jpg);
  background-size: 100%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-bottom: 3px solid $secondary;

  .home-poolLink {
    text-decoration: none;

    &:hover {
      #home-poolLearnUnderline {
        border-bottom: 4px solid $accent-bright;
      }
    }

    #home-poolTitle {
      @include title;
      padding-bottom: 1rem;
      color: $accent-bright !important;
      letter-spacing: 2.5px !important;
      text-shadow: $header-outline-thiccc;
    }

    #home-poolSubtitle {
      padding-bottom: 1rem !important;
    }

    #home-poolSubtitle,
    #home-poolLearnMore {
      @include subtitle;
      text-shadow: $header-outline-bright;
      color: $accent-bright-text !important;
    }

    #home-poolLearnUnderline {
      transition: border 0.3s linear;
      padding-bottom: 0.05rem;
      border-bottom: 3px solid $accent-bright-text;
    }
  }
}
