html {
  font-size: 32px;
}

html,
body {
  margin: 0px;
  padding: 0px;
  max-width: 100%;
  overflow-x: hidden;
}
