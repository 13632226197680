@import "../../../Styles/master";

.contact-methods {
  background-color: $primary;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .contact-method {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    color: $primary-text !important;
    @include paragraphText;
    text-indent: 0 !important;
    word-break: break-word;

    #contact-mailLink {
      word-break: break-all;
    }

    #contact-mailLink,
    #contact-phoneLink {
      color: $primary-text !important;
      text-decoration: none;
    }

    .contact-icon {
      padding-right: 0.1rem;
      font-size: $size-large;
      color: $accent-bright;
    }
  }
}
