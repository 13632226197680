@import "../../Styles/master";

.overview {
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
  border-top: 3px solid $secondary;
  border-bottom: 3px solid $secondary;
  background-color: $background-accent;

  .overview-title {
    padding-top: 0.25rem !important;
    padding-bottom: 0.5rem !important;

    @include responsive(desktop) {
      @include title;
    }

    @include responsive(desktopSmallerThan) {
      @include subtitle;
    }
  }

  .overview-paragraph {
    @include paragraphText;
    width: 70% !important;
    margin: auto;
    padding-bottom: 0.5rem !important;

    @include responsive(tabletSmall) {
      width: 90% !important;
    }
  }

  .overview-listContainer {
    padding-top: 0.25rem;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;

    @include responsive(tabletSmall) {
      padding-top: 0;
    }

    @include responsive(tabletSmall) {
      flex-wrap: wrap;
    }

    .overview-list {
      flex-basis: 100%;
      list-style: none;
      margin: 0;
      margin: auto;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      @include responsive(tabletSmall) {
        flex-basis: 100%;
      }

      @include responsive(thiccc) {
        flex-basis: 80%;
      }
      .overview-listItem {
        @include paragraphText;
        flex-basis: 50%;
        margin: 0 !important;
        padding-top: 0.25rem;
        word-wrap: nowrap;

        @include responsive(tabletSmall) {
          &:first-child {
            padding-top: 0;
          }
        }

        @include responsive(tabletSmall) {
          flex-basis: 100% !important;
          padding-bottom: 0.1rem !important;
        }

        @include responsive(desktopSmallerThan) {
          flex-basis: 50%;
        }

        @include responsive(widescreen) {
          flex-basis: 33%;
        }

        @include responsive(thiccc) {
          flex-basis: 25% !important;
        }
      }
    }
  }
}
