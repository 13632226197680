@import "../../Styles/master";

.services {
  background-image: url(../../Media/Images/backgrounds/background.jpg);
  background-size: 100%;

  #services-search {
    box-sizing: border-box;
    width: 90%;
    margin: auto;
    background-color: $background-transparent;
    box-shadow: 2px 2px 3px 0 $background;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0.25rem;
    max-height: 2.5rem;

    @include responsive(desktop) {
      width: 80%;
    }

    @include responsive(desktopSmallerThan) {
      width: 92%;
    }

    .services-searchBarAndIcon {
      display: flex;
      justify-content: center;
      width: 100%;

      #services-searchBar {
        box-sizing: border-box;
        padding: 0.25rem;
        height: 1rem;
        margin: 0;
        border: 1px solid $accent-dark;
        border-radius: 2px;
        flex-grow: 1;
        max-width: 70%;
        transition: box-shadow 0.3s linear;

        &:focus,
        &:hover {
          outline: none;
          box-shadow: 0px 0px 0.3rem $accent-bright;
        }
      }

      .services-searchIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin-right: 0.25rem;
        background-color: $accent-bright-text;
        cursor: pointer;
        transition: box-shadow 0.3s linear;

        &:hover {
          box-shadow: 0px 0px 0.3rem $accent-bright;
        }

        .services-searchIcon {
          color: $accent-bright;
          font-size: $size-large;
        }
      }
    }

    .services-searchResultCount {
      display: flex;
      align-items: center;
      justify-content: center;
      text-indent: 0 !important;
      padding-bottom: 0.1rem !important;
      padding-top: 0.25rem !important;
      @include paragraphText;
      width: 100% !important;
      color: $accent-bright-text;
      font-weight: bold;
    }

    #services-keyboardHider {
      height: 0px;
      overflow: hidden;
      visibility: hidden;
    }
  }

  .services-serviceContainer {
    &:first-child {
      padding-top: 0.5rem;
    }
    &:last-child {
      padding-bottom: 1rem;
    }
  }

  .services-service {
    width: 90%;
    margin: auto;
    background-color: $background-transparent;
    border-radius: 10px;
    box-shadow: 2px 2px 3px 0 $background;

    @include responsive(desktop) {
      width: 80%;
    }

    @include responsive(desktopSmallerThan) {
      width: 92%;
    }

    // fix ie non-transparent background
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-color: white;
    }

    .services-serviceTitleContainer {
      flex-basis: 100%;

      .services-serviceTitle {
        @include title;
        text-shadow: $header-outline;
        border-bottom: 2px solid $accent-bright;
        margin-bottom: 0.25rem;
      }
    }

    .services-serviceInfo {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
      align-items: center;

      .services-serviceDescriptionContainer {
        flex-basis: 100%;

        .services-serviceDescritpionItem {
          @include paragraphText;
          width: 100% !important;
        }
      }

      .services-serviceSeasonsContainer {
        flex-basis: 100%;

        .services-serviceSeasons {
          text-shadow: $header-outline;
          @include subtitle;

          .services-serviceSeason {
            text-shadow: none;
            @include paragraphText;
            width: 100% !important;
          }
          padding-bottom: 0.25rem;
        }
      }

      .services-serviceNotesContainer {
        flex-basis: 100%;

        .services-serviceNotes {
          @include paragraphText;
          font-style: italic;
          width: 100% !important;
        }
      }
    }

    .services-serviceMedia {
      flex-basis: 100%;
      display: flex;
      flex-wrap: wrap;

      .services-videoOnly,
      .services-imagesOnly {
        flex-basis: 100% !important;
      }

      .services-videoContainer:not(.services-videoOnly) {
        margin-bottom: 0.25rem;
      }

      .services-serviceImagePaneContainer:not(.services-imagesOnly),
      .services-videoContainer:not(.services-videoOnly) {
        flex-basis: 100%;

        @include responsive(thiccc) {
          flex-basis: 50% !important;
          width: 50% !important;
          max-width: 50% !important;
          overflow: hidden;
        }
      }

      @include responsive(thiccc) {
        .services-serviceImagePaneContainer:not(.services-imagesOnly) {
          border-left: 1px solid $accent-bright;
        }

        .services-videoContainer:not(.services-videoOnly) {
          margin-bottom: 0rem;
          border-right: 1px solid $accent-bright;
        }
      }

      .services-serviceImagePaneContainer {
        box-sizing: border-box;
        padding: 0.5rem;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;

        &.services-imagesOnly {
          .services-serviceImagePane {
            height: 60vh;
          }
        }

        @include responsive(desktop) {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        .services-serviceImagePane {
          flex-basis: 100%;
          max-width: 100%;
          background-size: cover;
          background-position: center;
          height: 50vw;

          @include responsive(thiccc) {
            height: 50vh;
          }
        }

        .services-imageSelectionPane {
          box-sizing: border-box;
          display: flex;
          min-width: 100%;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          .services-imageSelectionTile {
            box-sizing: border-box;
            flex-basis: 20%;
            background-size: cover;
            background-position: center;
            margin: 3px;
            outline: solid 3px $background-transparent;
            transition: outline 0.3s linear;

            @include responsive(desktopSmall) {
              flex-basis: 15%;
            }

            @include responsive(widescreen) {
              flex-basis: 10%;
            }

            &:not(.services-imageTileSelected) {
              cursor: pointer;
            }

            &:hover {
              outline: solid 3px $secondary;
            }

            &.services-imageTileSelected {
              outline: solid 3px $secondary;
            }

            &:before {
              content: "";
              float: left;
              padding-top: 100%;
            }
          }
        }
      }

      .services-videoContainer {
        position: relative;
        flex-basis: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0.5rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .services-videoCaption {
          border-bottom: 2px solid $accent-bright;
        }

        @include responsive(thiccc) {
          min-height: 100%;
          .services-videoCaption {
            border-bottom: none;
          }
        }

        @include responsive(desktop) {
          padding-left: 1rem;
          padding-right: 1rem;
        }

        &.services-videoOnly {
          .services-video {
            flex-basis: auto !important;
          }

          @include responsive(desktop) {
            padding-left: 1rem;
            padding-right: 1rem;
          }

          @include responsive(desktopSmallerThan) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }

        .services-video {
          max-width: 100%;
          flex-basis: 100%;

          @include responsive(thiccc) {
            height: 50vh;
            object-fit: fill;
          }
        }

        .services-videoCaption {
          @include paragraphText;
          width: 100% !important;

          padding-top: 0.25rem;
          flex-basis: 100%;
        }
      }
    }
  }
}
