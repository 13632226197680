@import "../../Styles/master";

#footer {
  background-color: $secondary;
  padding-bottom: 0.1rem;

  #footer-logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsive(tablet) {
      height: 2.5rem;
    }

    @include responsive(desktopSmall) {
      height: 3.5rem;
    }

    #footer-logo,
    #footer-logoLink {
      height: 3rem;

      @include responsive(tablet) {
        height: 2rem;
      }
    }
  }

  #footer-links {
    display: flex;
    justify-content: center;
    align-items: center;

    @include responsive(desktopSmallerThan) {
      flex-wrap: wrap;
    }

    .footer-link {
      flex-basis: 100%;
      font-family: $link-font;
      font-weight: 500;
      letter-spacing: 2px !important;
      font-size: $size-small;
      text-decoration: none;
      text-align: center;
      color: $accent-bright-text;
      font-weight: 600;
      transition: color 0.3s linear;

      @include responsive(desktop) {
        white-space: pre;
        flex-basis: 17%;
        padding-right: 2%;

        &:first-child {
          padding-left: 3.5%;
        }

        &:last-child {
          padding-right: 3.5%;
        }
      }

      @include responsive(desktopSmallerThan) {
        padding-bottom: 0.5rem;

        &:last-child {
          padding-bottom: 0rem;
        }
      }

      &:hover,
      &.footer-link-active {
        color: $accent-bright;
      }
    }
  }

  #footer-info {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    font-family: $standard-font;
    font-size: $size-tiny;

    @include responsive(tablet) {
      height: auto;
      justify-content: center;
      padding-top: 0.25rem !important;

      #footer-socialIcons,
      #footer-copy,
      #footer-built {
        flex-basis: 100%;
        text-align: center;
      }

      #footer-socialIcons {
        padding-bottom: 0.1rem;
      }

      #footer-copy {
        padding-bottom: 0.25rem;
      }

      #footer-built {
        margin-left: 0;
      }
    }

    #footer-socialIcons {
      display: flex;
      justify-content: center;
      align-items: center;

      .footer-socialIconContainer {
        padding-left: 0.25rem;

        .footer-socialIcon {
          width: 1rem;
          height: 1rem;
          border-radius: 50%;

          &:hover {
            box-shadow: 0 0 3px 2px $accent-bright;
          }
        }
      }
    }

    #footer-copy {
      padding-left: 0.25rem;
      color: $accent-bright-text;
      font-weight: 600;
    }

    #footer-built {
      font-family: $builtByLink-font;
      font-weight: 600;
      margin-left: auto;
      padding-right: 0.15rem;

      #footer-builtLink {
        text-decoration: none;
        color: $accent-bright-text;
        transition: color 0.3s linear;

        &:hover {
          color: $ab-theme-medium;
          text-decoration: underline;
        }
      }
    }
  }
}
