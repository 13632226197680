@import "../../Styles/master";

#header {
  @include parallax;

  #header-text {
    font-family: $heading-font;
    font-size: $size-extraThiccc;
    color: $accent-bright;
    background-color: $accent-bright-transparent-background;
    padding: 0.25rem;
    border-radius: 1px;
    text-align: center;
    font-weight: normal;
    text-shadow: $header-outline-thiccc;
    letter-spacing: 2.5px !important;
    border-radius: 5px;

    @include responsive(desktopSmallerThan) {
      font-size: $size-pageHeading;
      padding: 0.15rem;
    }

    @include responsive(mobile) {
      font-size: $size-subheading;
      padding: 0.15rem;
    }
  }
}
